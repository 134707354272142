import React from "react"
import Layout from '../components/Layout'
import Seo from "../components/Seo"
import {MdEmail} from 'react-icons/md'


const contact = () => {
  return (
    <Layout>
      <Seo title="Contact" description="Contact Me" />
      <section className='contact-page'>
        <article className="contact-form">
          <h3>get in touch</h3>
          <form name='contact' action="https://formspree.io/f/mbjwlpjw" method="POST">
            <div className="form-group">
              
              
              <input type='hidden' name='contact' value='contact' />
              <input 
                type='text' 
                placeholder="name" 
                className='form-control' 
                name='name'
              />
              <input 
                type='email'
                placeholder="email"
                className="form-control"
                name='email'
              />
              <textarea 
                name='message'
                rows='5'
                placeholder="message"
                className="form-control"
                name='message'
              />
            </div>
            <button type="submit" className="submit-btn btn">
              submit
            </button>
          </form>
        </article>
        <div>
          <h4 align='center'>Email:</h4>
          <div className="underline"></div>
          <a href="mailto:kenny@kennyleebates.com" className="email-info">
          <MdEmail styleClass="footer-links" /><span> Kenny@kennyleebates.com</span>
          </a>
        </div>
        
      </section>
    </Layout>
  )
}

export default contact
